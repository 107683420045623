import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CheckRoute = ({ setCurrentActiveMenu }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const routeMappings = {
    askbyz: t('tab.AskBot'),
    report: t('tearSheet.customReportsHeadder'),
    tearsheet: t('tearSheet.heading'),
    template: t('tearSheet.heading'),
    tracker: t('tracker.heading'),
    'help-center': t('headder.helpCenter'),
    about: 'Company',
    dashboard: t('headder.dashBoard')
  };

  useEffect(() => {
    let newValue = '';
    const pathname = location.pathname;
    for (const [key, value] of Object.entries(routeMappings)) {
      if (pathname.includes(key)) {
        newValue = value;
      }
    }
    setCurrentActiveMenu(newValue);
  }, [location, routeMappings, setCurrentActiveMenu]);

  return null;
};

export default CheckRoute;
