import React from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useSelector, useDispatch } from 'react-redux';
import Tabs from '../Tabs';
import { ReactComponent as TearSheetIcon } from '../../../components/icons/TearSheetIcon.svg';
import { ReactComponent as TearSheetHoveredIcon } from '../../../components/icons/TearSheetHoveredIcon.svg';
import { useTranslation } from 'react-i18next';
import HeartIcon from './HeartIcon';
import MCAIconComp from './MCAIconComp';

// import { Link } from 'react-router-dom';
import {
  setCompanyDetails,
  setCompanyName,
  setCompanyCin,
  updatePreviousRoute,
  setNews
} from '../action';
import BusinessIcon from '@mui/icons-material/Business';
import CancelIcon from '@mui/icons-material/Cancel';
import Accordian from '../Accordians';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import SendIcon from '@mui/icons-material/Send';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import './company.css';

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: '1px',
    borderRadius: '12px',
    borderColor: '#E3EBFD !important'
  },
  questionText: {
    fontFamily: 'Satoshi',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    color: '#767676',
    padding: '12px 10px',
    textAlign: 'left'
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiInputBase-input': {
      height: '11px !important',
      backgroundColor: '#F0F0F0 !important',
      borderRadius: '12px 0 0 12px'
    },
    padding: '10px !important',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      color: '#000000',
      fontWeight: 600,
      background: 'none'
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#4D4D4D',
      opacity: 1
    },
    '& .MuiOutlinedInput-adornedEnd': {
      backgroundColor: '#F0F0F0',
      borderRadius: '12px 12px 12px 12px'
    }
  },
  buttonSaveFeedback: {
    marginLeft: 'auto',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px',
    textTransform: 'none',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  cancleButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '26px',
    width: '65px'
  },
  likeBtn: {
    '&:hover': {
      backgroundColor: '#F7F9FD'
    }
  },
  flexCss: {
    display: 'flex',
    gap: '12rem'
  },
  detailHeading: {
    width: '10px'
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      fontWeight: 500,
      fontSize: '12px'
    },
    '&::placeholder': {
      fontSize: '12px',
      fontWeight: 'bold'
    }
  },
  popoverPaper: {
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  },
  headerCompany: {
    display: 'flex',
    gap: '4px'
  },
  headerCompanyTab: {
    display: 'flex',
    backgroundColor: '#F7F9FD'
  },
  firstDiv: {
    background: '#EDEBEB',
    // height: '66px',
    // width: '88px',
    width: '72px',
    height: '66px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '8px',
    border: '1px solid #E8E8E8'
  },
  secondDiv: {
    background: '#FFFFFF',
    // height: '66px',
    // width: '88px',
    width: '72px',
    height: '66px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '8px',
    border: '1px solid #E8E8E8'
  },
  compareData: {
    color: '#004CFF',
    'font-size': '14px',
    float: 'right',
    'text-decoration': 'none',
    paddingRight: '5px'
  },
  companyLogo: {
    marginRight: '6px',
    display: 'flex'
  },
  companyHeading: {
    fontSize: '36px'
  },
  mainDiv: {
    padding: '4px',
    background: '#FFFFFF'
  },
  topLevelGrid: {
    overflowY: 'auto',
    height: '104.5%',
    [theme.breakpoints.down('sm')]: {
      height: '97%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  fontSize14: {
    fontSize: '14px!important'
  },
  fontSize12: {
    fontSize: '12px!important'
  },
  fontWeight500: {
    'font-weight': '501!important'
  },
  displayInline: {
    display: 'flex',
    'padding-top': '14px!important'
  },
  iconsMainDiv: {
    [theme.breakpoints.down('sm')]: {
      'margin-right': '3%'
    },
    [theme.breakpoints.up('sm')]: {
      'margin-right': '4%'
    }
  },
  iconParent: {
    display: 'inline-block',
    'font-size': '18px',
    'line-height': '30px',
    width: '30px',
    height: '30px',
    'border-radius': '36px',
    'background-color': '#EFF3FC',
    color: '#fff',
    margin: '0 3px 3px 0'
  },
  socialMediaIcon: {
    [theme.breakpoints.up('sm')]: {
      padding: '7px',
      width: '50%',
      height: '45%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      height: '45%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '45%'
    }
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'start',
    marginBottom: theme.spacing(2)
  },
  mapKey: {
    display: 'inline-block',
    minWidth: '100px',
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
    wordBreak: 'break-word',
    overflowWrap: 'break-word'
  },
  mapValue: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  },
  closeIcon: {
    borderRadius: '50%',
    transition: 'color 0.3s ease',
    boxSizing: 'border-box',
    backgroundColor: '#CDCDCD',
    '&:hover': {
      color: '#EB5B55',
      backgroundColor: '#FFFFFF'
    }
  },
  iconCircle: {
    color: 'white'
  },
  closeIconMain: {
    borderRadius: '50%',
    transition: 'color 0.3s ease',
    boxSizing: 'border-box',
    backgroundColor: '#E3EBFD',
    '&:hover': {
      color: '#EB5B55',
      backgroundColor: '#FFFFFF'
    }
  },
  iconCircleMain: {
    color: '#489ECD'
  },
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  dialogPaper: {
    margin: 0,
    width: '100%'
  },
  buttonSignin: {
    marginLeft: 'auto',
    textTransform: 'none',
    position: 'relative',
    zIndex: 1,
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  showOnDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none!important'
    }
  },
  hideOnDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none!important'
    }
  },
  compIconTab: {
    'max-width': '36px',
    'max-height': '36px',
    'object-fit': 'contain',
    'font-size': '16px'
  },
  backArrow: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    width: '75px'
  },
  wordbreak: {
    [theme.breakpoints.down('xs')]: {
      'word-break': 'break-all'
    }
  },
  companyList: {
    height: '300px',
    overflow: 'auto',
    margin: '2px 0px !important'
  },
  watchlistCls: {
    'border-radius': '8px',
    border: '1px solid rgb(232, 232, 232)'
  },
  companyLi: {
    paddingLeft: '0px !important',
    display: 'flex !important',
    '&:hover': {
      borderRadius: '3px',
      opacity: '0.9',
      background: 'rgb(248, 250, 253) !important'
    }
  },
  removeLink: {
    fontWeight: 'lighter',
    fontSize: '13px',
    cursor: 'pointer',
    color: 'blue',
    margin: '0px 10px',
    '&:hover': {
      color: theme.palette.error.main,
      textDecoration: 'underline'
    }
  },
  watchlistNm: {
    fontWeight: 'bold',
    fontSize: '15px',
    color: '#000000B2'
  },
  btnNewWt: {
    display: 'flex',
    border: '1px solid rgb(232, 232, 232)',
    'border-radius': '8px',
    padding: '20px 10px',
    marginBottom: '10px'
  },
  btnWtp: {
    fontFamily: 'Satoshi',
    fontWeight: 'normal',
    fontSize: '14px',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  companyName: {
    fontSize: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px'
    }
  },
  tooltipStyle: {
    padding: '20px !important',
    backgroundColor: 'transparent !important',
    boxShadow: 'none'
  },
  iconButton: {
    cursor: 'pointer'
  },
  defaultIcon: {
    display: 'inline'
  },
  hoverIcon: {
    display: 'none'
  },
  iconButtonHover: {
    '&:hover $defaultIcon': {
      display: 'none'
    },
    '&:hover $hoverIcon': {
      display: 'inline'
    }
  },
  newCard: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #F0F0F0',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px 0px #0000000D'
  },
  newsCardContent: {
    flexGrow: '8!important',
    width: '80% !important',
    padding: '7px !important'
  },
  newsDateTime: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  },
  newsTitle: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  },
  newsSource: {
    fontFamily: 'Satoshi !important',
    fontSize: '14px !important',
    color: '#666666 !important',
    fontWeight: '500 !important'
  },
  newsCardMedia: {
    flexGrow: '2 !important',
    width: '20% !important',
    borderRadius: '7px !important',
    height: '60px !important',
    objectFit: 'cover !important'
  },
  newsSeeMore: {
    fontFamily: 'Satoshi !important',
    fontWeight: 'bold !important',
    fontSize: '12px !important'
  }
}));

const AskBotCompany = ({
  showCompaniesTab,
  setShowCompaniesTab,
  matches,
  setValue,
  customWidth,
  requestMcaInfo
}) => {
  console.log('ask bot company render!');
  const { t } = useTranslation();
  const companyDetails = useSelector((state) => state.tokenReducer.companyDetails);
  const news = useSelector((state) => state.tokenReducer.news);
  const previousRouteNavigation = useSelector(
    (state) => state.tokenReducer.previousRouteNavigation
  );
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentActiveCompany =
    companyDetails && companyDetails.length > 0
      ? companyDetails.find((x) => x.active === true)
      : null;
  const allTabsData =
    currentActiveCompany &&
    currentActiveCompany.data &&
    currentActiveCompany.data.tabs &&
    currentActiveCompany.data.tabs.length > 0
      ? currentActiveCompany.data.tabs
      : [];
  const APITabs = allTabsData.filter((x) => typeof x == 'object').map((x) => Object.keys(x)[0]);
  const setActiveCompany = (currentCompany) => {
    let updatedCompanyDetails = [...companyDetails];
    updatedCompanyDetails = updatedCompanyDetails.map((item) => ({ ...item, active: false }));

    const existingIndex = updatedCompanyDetails.findIndex(
      (obj) => obj.data.base.CIN === currentCompany.data.base.CIN
    );
    updatedCompanyDetails[existingIndex].active = true;

    if (companyName !== '' || companyCin !== '') {
      const activeCompany = updatedCompanyDetails.find((obj) => obj.active === true);
      dispatch(setCompanyName(activeCompany.data.base.Name));
      dispatch(setCompanyCin(activeCompany.data.base.CIN));
    }

    dispatch(setCompanyDetails([...updatedCompanyDetails]));
    if (matches) setShowCompaniesTab(false);
  };
  const tabPanels =
    APITabs && APITabs.length > 0
      ? APITabs.map(() => {
          return Accordian;
        })
      : [];
  const config = {
    tabs: APITabs,
    tabPanels,
    componentData: { currentActiveCompany, allTabsData, APITabs, classes }
  };
  const companyName = useSelector((state) => state.tokenReducer.companyName);
  const companyCin = useSelector((state) => state.tokenReducer.companyCin);
  const deleteCompanyTab = (cin) => {
    let updatedCompanyDetails = [...companyDetails];
    let updatedNews = JSON.parse(JSON.stringify(news));
    delete updatedNews[cin];
    dispatch(setNews(updatedNews));
    if (updatedCompanyDetails.length === 1) {
      dispatch(setCompanyDetails([]));
      return;
    }
    const existingIndex = updatedCompanyDetails.findIndex((obj) => obj.data.base.CIN === cin);
    if (updatedCompanyDetails[existingIndex].active === false) {
      updatedCompanyDetails.splice(existingIndex, 1);
    } else {
      if (existingIndex === 0) {
        updatedCompanyDetails = [
          ...updatedCompanyDetails.slice(0, existingIndex),
          { ...updatedCompanyDetails[existingIndex + 1], active: true },
          ...updatedCompanyDetails.slice(existingIndex + 2)
        ];
      } else {
        if (existingIndex === updatedCompanyDetails.length - 1) {
          updatedCompanyDetails = [
            ...updatedCompanyDetails.slice(0, existingIndex - 1),
            { ...updatedCompanyDetails[existingIndex - 1], active: true }
          ];
        } else {
          updatedCompanyDetails = [
            ...updatedCompanyDetails.slice(0, existingIndex),
            { ...updatedCompanyDetails[existingIndex + 1], active: true },
            ...updatedCompanyDetails.slice(existingIndex + 2)
          ];
        }
      }
      if (companyName !== '' || companyCin !== '') {
        const activeCompany = updatedCompanyDetails.find((obj) => obj.active === true);
        dispatch(setCompanyName(activeCompany.data.base.Name));
        dispatch(setCompanyCin(activeCompany.data.base.CIN));
      }
    }
    dispatch(setCompanyDetails([...updatedCompanyDetails]));
  };

  return (
    <>
      {showCompaniesTab ? (
        <Grid
          id="companyMainGrid"
          item
          sm={12}
          className={classes.hideOnDesktop}
          style={
            customWidth ? { maxWidth: `calc(${customWidth})`, width: `calc(${customWidth})` } : {}
          }
        >
          <List
            id="companyMainGridList"
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {companyDetails &&
              companyDetails.length > 0 &&
              companyDetails.map((x, i) => {
                return (
                  <ListItemButton
                    id="companyMainGridListItemButton"
                    key={i}
                    onClick={() => {
                      setActiveCompany(x);
                    }}
                  >
                    <ListItemIcon id="companyMainGridListItemIcon">
                      {x?.data?.base?.Logo ? (
                        <img src={x?.data?.base?.Logo} alt="Logo" className={classes.compIconTab} />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText primary={x?.data?.base?.Name} />
                    <KeyboardArrowRightIcon />
                  </ListItemButton>
                );
              })}
          </List>
        </Grid>
      ) : (
        <Grid
          id="companyMainGrid"
          item
          xs={12}
          sm={12}
          className={classes.topLevelGrid}
          sx={{ backgroundColor: '#FFFFFF', borderRadius: '24px' }}
          style={
            customWidth ? { maxWidth: `calc(${customWidth})`, width: `calc(${customWidth})` } : {}
          }
        >
          <div
            className={`${classes.backArrow} ${classes.hideOnDesktop}`}
            id="companyMainGridChild"
          >
            <ArrowBackIcon
              id="companyMainGridChildArBkIcon"
              onClick={() => {
                if (matches) {
                  dispatch(updatePreviousRoute(''));
                  if (previousRouteNavigation == 'askbyz') {
                    setValue(0);
                    setShowCompaniesTab(true);
                  } else if (previousRouteNavigation) {
                    navigate(previousRouteNavigation);
                  } else {
                    setShowCompaniesTab(true);
                  }
                }
              }}
            />{' '}
            {showCompaniesTab}
          </div>
          <div
            className={`${classes.headerCompanyTab} ${classes.showOnDesktop}`}
            id="companyMainGridChildDiv"
          >
            {companyDetails &&
              companyDetails.length > 0 &&
              companyDetails.map((x, i) => {
                if (x.active == false)
                  return (
                    <div key={i} className={classes.firstDiv} id="companyMainGridChildfirstDiv">
                      <div
                        id="companyMainGridChildfirstDivCh"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                          width: '72px',
                          height: '66px'
                          // width: '88px',
                          // height: '66px'
                        }}
                        onClick={() => {
                          setActiveCompany(x);
                        }}
                      >
                        {x?.data?.base?.Logo ? (
                          <img
                            id="companyMainGridChildfirstImg"
                            src={x?.data?.base?.Logo}
                            alt="Logo"
                            style={{
                              maxWidth: '60%',
                              maxHeight: '60%',
                              objectFit: 'contain'
                            }}
                          />
                        ) : (
                          <BusinessIcon
                            id="companyMainGridChildfirstBusIco"
                            style={{
                              maxWidth: '75%',
                              maxHeight: '75%',
                              objectFit: 'contain'
                            }}
                          />
                        )}
                        <CancelIcon
                          id="companyMainGridChildfirstCanIco"
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteCompanyTab(x.data.base.CIN);
                          }}
                          className={`${classes.closeIcon} ${classes.iconCircle}`}
                          style={{
                            position: 'absolute',
                            top: 1.5,
                            right: 1.5,
                            fontSize: '14px',
                            margin: 0,
                            padding: 0,
                            borderRadius: '50%'
                          }}
                        />
                      </div>
                    </div>
                  );

                return (
                  <div key={i} className={classes.secondDiv} id="companyMainDivSen">
                    <div
                      id="companyMainDivSenCh"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        width: '72px',
                        height: '66px'
                        // width: '88px',
                        // height: '66px'
                      }}
                    >
                      {x?.data?.base?.Logo ? (
                        <img
                          id="companyMainDivSenChImg"
                          src={x?.data?.base?.Logo}
                          alt="Logo"
                          style={{
                            maxWidth: '65%',
                            maxHeight: '65%',
                            objectFit: 'contain'
                          }}
                        />
                      ) : (
                        <BusinessIcon
                          id="companyMainDivSenChBusIco"
                          style={{
                            maxWidth: '80%',
                            maxHeight: '80%',
                            objectFit: 'contain'
                          }}
                        />
                      )}
                      <CancelIcon
                        id="companyMainDivSenChCalIco"
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteCompanyTab(x.data.base.CIN);
                        }}
                        className={`${classes.closeIcon} ${classes.iconCircle}`}
                        style={{
                          position: 'absolute',
                          top: 1.5,
                          right: 1.5,
                          fontSize: '14px',
                          margin: 0,
                          padding: 0,
                          borderRadius: '50%'
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={classes.mainDiv} id="companyMainDivch">
            <div className={classes.headerCompany} id="companyMainDivchHdComp">
              <div
                id="companyMainDivchHdCompCh"
                style={{
                  display: 'flex',
                  minHeight: '80px',
                  width: '100%',
                  padding: '0px 5px',
                  alignItems: 'center'
                }}
              >
                <div
                  id="companyMainDivchHdCompChCh"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    {currentActiveCompany?.data?.base?.Logo ? (
                      // <img
                      //   id="companyMainDivchHdCompChChImg"
                      //   src={currentActiveCompany?.data?.base?.Logo}
                      //   alt="Logo"
                      //   style={{
                      //     maxWidth: '65px',
                      //     maxHeight: '65px'
                      //   }}
                      // />
                      <img
                        id="companyMainDivchHdCompChChImg"
                        src={currentActiveCompany?.data?.base?.Logo}
                        alt="Logo"
                        style={{
                          maxWidth: '60px',
                          maxHeight: '60px',
                          objectFit: 'contain',
                          padding: '0px 5px'
                        }}
                      />
                    ) : (
                      <BusinessIcon
                        id="companyMainDivchHdCompChChBusIco"
                        style={{
                          width: '55px',
                          height: '55px'
                        }}
                      />
                    )}
                    <div
                      id="companyMainDivchHdCompChChCh"
                      style={{
                        fontFamily: 'Satoshi',
                        paddingLeft: '5px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <p
                        id="companyMainDivchHdCompChChChp"
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: '600',
                          marginRight: '40px',
                          margin: '0px'
                        }}
                        className={classes.companyName}
                      >
                        {currentActiveCompany?.data?.base?.Name}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      id="companyMainDivchHdCompChChChpCh"
                      style={{
                        right: 5,
                        fontSize: '24px',
                        margin: 0,
                        padding: 0,
                        cursor: 'pointer',
                        display: 'flex'
                      }}
                    >
                      <Tooltip className={classes.tooltipStyle} title={t('tearSheet.heading')}>
                        <IconButton
                          onClick={() => {
                            const link = `/report?entity_type=company&entity_id=${currentActiveCompany?.data?.base?.CIN}&template_id=${process.env.REACT_APP_DEFAULT_TEMPLATE_ID}`;
                            window.open(link, '_blank');
                          }}
                          className={`${classes.iconButton} ${classes.iconButtonHover}`}
                        >
                          <TearSheetIcon className={classes.defaultIcon} />
                          <TearSheetHoveredIcon className={classes.hoverIcon} />
                        </IconButton>
                      </Tooltip>
                      <MCAIconComp
                        cin={currentActiveCompany?.data.base.CIN}
                        requestMcaInfo={requestMcaInfo}
                      />
                      {companyDetails && companyDetails.length > 0 && (
                        <HeartIcon company={currentActiveCompany} isDashBoard={false} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Paper elevation={0} style={{ width: '100%' }} id="companyMainDivchHdCompPaper">
                <div
                  style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                  id="companyMainDivchHdCompPaperCh"
                >
                  <div style={{ flex: 1 }} id="companyMainDivchHdCompPaperChCh">
                    <Tabs config={config} id="companyMainDivchHdCompPaperTab" />
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};

export default React.memo(AskBotCompany);
