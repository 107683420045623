import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
// import { postApi } from '../../services/token';
import { AppBar, Toolbar, IconButton, Button, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import ProfilePopup from '../../profile/ProfilePopup';
import './header.css';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { AuthIsSignedIn, AuthIsNotSignedIn, AuthStatus } from '../../auth/contexts/authContext';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as SearchIcon } from '../../../components/icons/SearchIcon.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setHomeTabs,
  setCompanyApiContext,
  setCompanyMenuClickedStatus,
  isShowCompanyPage,
  setWhiteLabelDetails,
  setCurrentStepNumber,
  endTourGuide
} from '../action';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { AuthContext } from '../../auth/contexts/authContext';
import { getNameFunc } from '../utils/usernameFunc';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as SeeMore } from '../../../components/icons/SeeMore.svg';
import Typography from '@material-ui/core/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getApi } from '../../../services/token';
import { routesWithoutHeaderAndFooter } from '../../utils/utils';
import { useSelector } from 'react-redux';
import WhiteLabelLogo from './whiteLabelLogo';
import StepPopover from '../../tourGuide';
import { getMainDomain, handleSubDomain } from '../../utils/subDomainUtil';
import CheckRoute from './checkRoute';
import { jwtDecode } from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
  logo: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    opacity: 1
  },
  buttonLink: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  askbotBefore: {
    '&::before': {
      top: '30px'
    }
  },
  tearSheetBefore: {
    '&::before': {
      top: '35px'
    }
  },
  dashboardPopOverbutton: {
    '&::before': {
      top: '35px'
    }
  },
  askBotPopOverbutton: {
    position: 'relative',
    background: 'white',
    'border-radius': '25px',
    zIndex: 99999,
    'padding-left': '19px!important',
    '&::before': {
      border: '10px solid transparent',
      content: '""',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #ffffff transparent',
      transform: 'translateX(-50%)',
      zIndex: 9999
    }
  },
  logoLink: {
    textDecoration: 'none'
  },
  buttonSignin: {
    position: 'relative',
    zIndex: 1,
    height: '38px',
    borderRadius: '4px',
    background: 'linear-gradient(to right, #06BCC1, #004CFF)',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '1px',
      right: '1px',
      top: '1px',
      bottom: '1px',
      borderRadius: '3px',
      backgroundColor: '#F7F9FD',
      zIndex: -1,
      transition: '200ms'
    }
  },
  link: {
    '&:hover': {
      backgroundColor: '#F7F9FD'
    }
  },
  showOnDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  anchorLink: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '16px!important'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginRight: '8px!important'
    }
  },
  root: {
    width: '400px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E3EBFD',
      '& fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&:hover fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid #004CFF',
        borderRadius: '3px',
        opacity: '0.1'
      }
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#000000',
      opacity: 1
    },
    '& .MuiInputBase-input': {
      height: '1px !important',
      fontSize: '14px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '200px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6px',
      width: 'calc(100% - 12px)',
      backgroundColor: '#F8F9FD'
    }
  },
  drawer: {
    width: '100%',
    background: '#F8F9FD'
  },
  menuIconWithBorder: {
    border: '1px solid #004CFF',
    borderRadius: '2px'
  },
  betaLogo: {
    fontSize: '17px',
    color: '#06B5BA',
    fontFamily: 'Satoshi',
    marginBottom: '20px',
    marginLeft: '2px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '13px',
      fontSize: '14px'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '18px',
      fontSize: '12px'
    }
  },
  headerMainDiv: {
    background: '#FFFFFF',
    boxShadow: '1px 2px 8px 0px rgba(0, 0, 0, 0.04)'
  },
  mobileBetaLogo: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '3px'
    }
  },
  '@media print': {
    headerMainDiv: {
      visibility: 'hidden'
    },
    printLogo: {
      visibility: 'visible',
      position: 'absolute',
      top: 0,
      left: 0
    }
  },
  demoBtn: {
    height: '39px',
    background: `transparent linear-gradient(90deg, #004CFF 0%, #06BCC1 100%) 0% 0% no-repeat padding-box`,
    textTransform: 'none',
    margin: '0px 16px 0px 8px',
    padding: '0px 14px'
  },
  menuDiv: {
    '& ul': {
      paddingBottom: '0px'
    }
  },
  gradientCls: {
    'background-image':
      'linear-gradient(to right, rgb(0, 76, 255) 0%, rgb(6, 188, 193) 100%)!important',
    'background-clip': 'text!important',
    '-webkit-text-fill-color': 'transparent!important'
  }
}));

const url = process.env.REACT_APP_API;
const disableWhiteLabel = process.env.REACT_APP_DISABLE_WHITE_LABEL;
const Header = ({ headerConfig }) => {
  const { t } = useTranslation();
  // const companyUrl = process.env.REACT_APP_COMPANY_API;
  const [currentActiveMenu, setCurrentActiveMenu] = useState('');
  const { setPrevRoute } = headerConfig;
  const location = useLocation();
  const prevPathName = location?.pathname;
  const pathExcluded = ['signin', 'verify', 'forgotpassword'];
  const dispatch = useDispatch();
  if (prevPathName && !pathExcluded.find((x) => prevPathName.includes(x))) {
    setPrevRoute(prevPathName);
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const signinPadding = isTab ? '5px' : '20px';
  const classes = useStyles();
  const navigate = useNavigate();
  const { authStatus } = useContext(AuthContext);
  const contactBtnText = useMemo(() => {
    return authStatus === 2 ? t('headder.contactSupport') : t('headder.demo');
  }, [authStatus]);
  // const [companyNameOptions, setCompanyNameOptions] = useState([]);
  // const getCompanyData = async () => {
  //   const companyListData = await postApi(`${companyUrl}/list-companies`, {
  //     page_limit: 500,
  //     page_number: 1
  //   });
  //   if (companyListData && companyListData.data) {
  //     const parsedData = companyListData.data.data.company_list;
  //     const option = parsedData.map((data) => ({ field: data.name, cin: data.cin }));
  //     setCompanyNameOptions(option);
  //   }
  // };

  // useEffect(() => {
  //   getCompanyData();
  // }, []);

  const handleChange = (event, value) => {
    if (value === null) {
      return;
    } else {
      dispatch(setHomeTabs(t('tab.AskBot')));
      const body = {
        cin: value.cin,
        name: value.name
      };
      dispatch(setCompanyApiContext(body));
      if (isTab) dispatch(isShowCompanyPage(prevPathName));
      navigate('/askbyz');
    }
  };
  const auth = useContext(AuthContext);
  const [nameChar, setNameChar] = useState(null);
  const [nameVal, setNameVal] = useState(null);
  const [step4Props, setStep4Props] = useState(null);
  // const [emailVal, setEmailVal] = useState(null);
  const [disableHeader, setDisableHeader] = useState(false);
  const [currentUserFname, setCurrentUserFname] = useState('');
  const whiteLabelDetails = useSelector((state) => state.tokenReducer.whiteLabelDetails);
  const getWhiteLabelDetails = async () => {
    if (
      whiteLabelDetails &&
      Object.keys(whiteLabelDetails).length != 0 &&
      authStatus === AuthStatus.SignedIn
    ) {
      return;
    }
    try {
      const res = await getApi(`${url}/v1/user/user_white_label_details`);
      const labelDetails = res.data.data;
      dispatch(setWhiteLabelDetails({ ...labelDetails }));
    } catch (err) {
      console.log(err);
      dispatch(setWhiteLabelDetails({}));
      handleSubDomain(false);
    }
  };
  useEffect(() => {
    const getName = async () => {
      try {
        const { nameVal, nameCharVar } = await getNameFunc(auth);
        setNameChar(nameCharVar);
        setNameVal(nameVal);
        const session = await auth.getSession();
        const token = session?.idToken?.jwtToken;
        const decoded = jwtDecode(token);
        if (window.clarity && decoded) {
          window.clarity('set', 'userUuid', decoded.sub);
          window.clarity('set', 'userOrgId', decoded.org_id);
        }
      } catch (err) {
        console.log(err);
      }
    };
    setDisableHeader(routesWithoutHeaderAndFooter.includes(location?.pathname));
    getName();
  }, []);

  const extractFirstName = (fullName) => {
    const names = fullName.trim().split(' ');
    if (names.length >= 2) {
      return names[0];
    }

    return fullName;
  };

  useEffect(() => {
    const getUserName = async () => {
      try {
        const { nameVal } = await getNameFunc(auth);
        setCurrentUserFname(extractFirstName(nameVal));
      } catch (err) {
        console.log(err);
      }
    };
    if (authStatus === 2) getUserName();
    if (disableWhiteLabel != 'true') {
      getWhiteLabelDetails();
    }
  }, [authStatus]);

  const storeCurrentStep = useSelector((state) => state.tokenReducer.currentStep);
  const storeShowTourGuide = useSelector((state) => state.tokenReducer.showTourGuide);
  const buttonRef = useRef(null);
  const buttonRefTearSheet = useRef(null);
  const buttonRefLiveTracker = useRef(null);
  const buttonRefDashboard = useRef(null);

  useEffect(() => {
    if (storeCurrentStep && storeShowTourGuide) {
      stepConfiguration(storeCurrentStep);
    }
  }, [storeCurrentStep, storeShowTourGuide]);

  const onNextPrevClick = (currStep) => {
    dispatch(setCurrentStepNumber(currStep));
  };

  const stepConfiguration = (currentStep) => {
    if (currentStep !== storeCurrentStep) {
      onNextPrevClick(currentStep);
    } else {
      if (currentStep === 1) {
        setCurrentStep(step1Obj);
        setAnchorElStep(buttonRefDashboard.current);
        setStep4Props(null);
      } else if (currentStep === 2) {
        setCurrentStep({
          step: currentStep,
          totalSteps: 6,
          paraHeading: 'Ask Byz',
          para: 'Byz can help you gain deeper understanding and insights about the private markets, including companies, sectors and key people. Ask any question and Byz will answer it based on publicly available data or your own uploaded documents. It will also suggest relevant follow-up questions to guide you along your analysis.',
          onClickPrev: () => {
            onNextPrevClick(currentStep - 1);
          },
          onclickNext: () => {
            onNextPrevClick(currentStep + 1);
          }
        });
        setAnchorElStep(buttonRef.current);
        setStep4Props(null);
      } else if (currentStep === 3) {
        setCurrentStep({
          step: currentStep,
          totalSteps: 6,
          paraHeading: 'Tear Sheet',
          para: 'Use the tear sheet to generate a quick and comprehensive report about a company, based on a pre-defined template or list of questions. Use the PrivateBlok template with a valuable list of generic questions, or define your own. The template can be re-used across multiple companies.',
          onClickPrev: () => {
            onNextPrevClick(currentStep - 1);
          },
          onclickNext: () => {
            onNextPrevClick(currentStep + 1);
          }
        });
        setAnchorElStep(buttonRefTearSheet.current);
        setStep4Props(null);
      } else if (currentStep === 4) {
        setCurrentStep({
          step: currentStep,
          totalSteps: 6,
          paraHeading: 'Live Tracker',
          para: 'Live Tracker is your one-stop solution for being updated with key business, finance, and management news for your preferred list of companies and sectors, aggregated across all major news sources, and summarized with pertinent information for quick consumption.',
          onClickPrev: () => {
            onNextPrevClick(currentStep - 1);
          },
          onclickNext: () => {
            onNextPrevClick(currentStep + 1);
          }
        });
        setAnchorElStep(buttonRefLiveTracker.current);
        setStep4Props(null);
      } else if (currentStep === 5) {
        setCurrentStep(null);
        setStep4Props({
          step: currentStep,
          totalSteps: 6,
          paraHeading: 'Document Library',
          para: 'Document library allows you to upload your proprietary documents including research, analyses and updates to a segregated private area for your organisation. These documents will then be used alongside the publicly available information in the platform to enrich and deliver the best possible answers to your questions. Tag each uploaded document to a company, sector or miscellaneous to guide its use.',
          note: 'Please note: Your proprietary data will never be used to train our models, or answer questions for any user outside your organisation.',
          onClickPrev: () => {
            onNextPrevClick(currentStep - 1);
          },
          onclickNext: () => {
            onNextPrevClick(currentStep + 1);
          }
        });
        setAnchorElStep(null);
      } else if (currentStep === 6) {
        setCurrentStep(null);
        setAnchorElStep(null);
        setStep4Props(null);
        navigate('/explore-all-companies');
      }
    }
  };

  const step1Obj = {
    step: 1,
    totalSteps: 6,
    topHeading: `Hello ${currentUserFname}!`,
    topSubHeading: 'Welcome to PrivateBlok, your AI Copilot for Private Markets.',
    paraHeading: 'Dashboard',
    para: 'Dashboard provides a comprehensive overview of key information about a company or theme, all in one place. It serves as a powerful starting point for deeper analysis, enabling you to quickly grasp the essential data points such as key people, key metrics for a company, at a glance.',
    onClickPrev: () => {},
    onclickNext: () => {
      onNextPrevClick(2);
    }
  };
  const [currentStep, setCurrentStep] = useState(null);
  const [anchorElStep, setAnchorElStep] = useState(null);

  const handleOncloseStep = () => {
    setCurrentStep(null);
    setAnchorElStep(null);
    setStep4Props(null);
  };

  const handleSkip = () => {
    dispatch(endTourGuide());
  };

  useEffect(() => {
    if (storeCurrentStep === 1 && storeShowTourGuide && buttonRefDashboard?.current) {
      setAnchorElStep(buttonRefDashboard.current);
    }
  }, [storeCurrentStep, storeShowTourGuide, buttonRefDashboard]);

  const showPricing = process.env.REACT_APP_ENABLE_PRICING;
  const isTearSheetEnabled = process.env.REACT_APP_ENABLE_TEARSHEET === 'true';

  const ResponsiveProductMenu = () => {
    return (
      <>
        <AuthIsSignedIn>
          <div
            style={{
              paddingTop: '6px',
              display: 'flex',
              justifyContent: 'flex-start',
              paddingLeft: '6px',
              flexDirection: 'column'
            }}
          >
            {showPricing === 'true' && (
              <Button style={{ justifyContent: 'flex-start' }} onClick={() => navigate('/pricing')}>
                <Link
                  to={'/pricing'}
                  target={'_self'}
                  className={`${classes.anchorLink + ' link-button'}`}
                >
                  {t('headder.pricing')}
                </Link>
              </Button>
            )}
            <Button style={{ justifyContent: 'flex-start' }} onClick={() => navigate('/report')}>
              <Link
                // to={'/report'}
                to={isTearSheetEnabled ? '/report' : '/tearsheet'}
                target={'_self'}
                className={`${classes.anchorLink + ' link-button'}`}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  {isTearSheetEnabled ? t('tearSheet.customReports') : t('tearSheet.heading')}
                </Typography>
              </Link>
            </Button>
            <Button
              style={{ justifyContent: 'flex-start', marginTop: '6px' }}
              onClick={() => navigate('/tracker')}
            >
              <Link
                to={'/tracker'}
                target={'_self'}
                className={`${classes.anchorLink + ' link-button'}`}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  {t('tracker.heading')}
                </Typography>
              </Link>
            </Button>
            <Button
              style={{ justifyContent: 'flex-start', marginTop: '6px' }}
              onClick={() => navigate('/help-center')}
            >
              <Link
                to={'/help-center'}
                target={'_self'}
                className={`${classes.anchorLink + ' link-button'}`}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  {t('headder.helpCenter')}
                </Typography>
              </Link>
            </Button>
            <Button
              style={{ justifyContent: 'flex-start', marginTop: '6px' }}
              onClick={() => {
                dispatch(setHomeTabs(t('tab.ExploreAllCompanies')));
                navigate('/explore-all-companies');
              }}
            >
              <Link
                to={'/report'}
                target={'_self'}
                className={`${classes.anchorLink + ' link-button'}`}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  {t('tab.ExploreAllCompanies')}
                </Typography>
              </Link>
            </Button>
            <Button
              style={{ justifyContent: 'flex-start', marginTop: '6px' }}
              onClick={() => {
                navigate('/watchlist-company');
              }}
            >
              <Link
                to={'/watchlist-company'}
                target={'_self'}
                className={`${classes.anchorLink + ' link-button'}`}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  {t('headder.watchListCompany')}
                </Typography>
              </Link>
            </Button>
            <Button
              style={{ justifyContent: 'flex-start', marginTop: '6px' }}
              onClick={() => {
                navigate('/askbyz');
              }}
            >
              <Link
                to={'/askbyz'}
                target={'_self'}
                className={`${classes.anchorLink + ' link-button'}`}
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  {t('tab.AskBot')}
                </Typography>
              </Link>
            </Button>
          </div>
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <Accordion
            style={{
              backgroundColor: '#F8F9FD'
            }}
            elevation={0}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<SeeMore />} // Make sure to define the SeeMore component
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <Typography
                  style={{
                    fontSize: '16px',
                    color: '#1D1D1D',
                    fontWeight: 600
                  }}
                >
                  Company
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0px 32px 16px' }}>
              <Button
                variant="text"
                style={{
                  fontFamily: 'Satoshi',
                  textTransform: 'none',
                  padding: '0px',
                  height: '40px',
                  width: '100%',
                  justifyContent: 'flex-start',
                  fontWeight: '600'
                }}
                onClick={() => {
                  navigate('/about');
                }}
              >
                <span style={{ display: 'flex', justifyItems: 'center' }}>
                  {t('headder.aboutUs')}
                </span>
              </Button>
              <br />
              <Button
                variant="text"
                style={{
                  fontFamily: 'Satoshi',
                  textTransform: 'none',
                  padding: '0px',
                  height: '40px',
                  width: '100%',
                  justifyContent: 'flex-start',
                  fontWeight: '600'
                }}
                onClick={() => {
                  window.open('https://www.linkedin.com/company/privateblok/jobs/', '_blank');
                }}
              >
                <span style={{ display: 'flex', justifyItems: 'center' }}>
                  {t('header.careers')}
                </span>
              </Button>
              <Button
                variant="text"
                style={{
                  fontFamily: 'Satoshi',
                  textTransform: 'none',
                  padding: '0px',
                  height: '40px',
                  width: '100%',
                  justifyContent: 'flex-start',
                  fontWeight: '600'
                }}
                onClick={() => {
                  navigate('/home');
                  dispatch(setCompanyMenuClickedStatus(true));
                }}
              >
                <span style={{ display: 'flex', justifyItems: 'center' }}>
                  {t('headder.contactUs')}
                </span>
              </Button>
            </AccordionDetails>
          </Accordion>
        </AuthIsNotSignedIn>
      </>
    );
  };

  const MobileView = () => {
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const handleClick = () => {
      setOpenMobileMenu(true);
    };

    const handleClose = () => {
      setOpenMobileMenu(false);
    };

    const handleSearchClose = () => {
      setSearchOpen(false);
    };

    const CustomPopper = (props) => {
      return (
        <div
          {...props}
          style={{
            paddingLeft: '6px',
            paddingRight: '6px',
            backgroundColor: '#F8F9FD'
          }}
        />
      );
    };
    const CustomListbox = (props) => {
      return (
        <div
          {...props}
          style={{
            maxHeight: '100%',
            backgroundColor: '#F8F9FD',
            border: '2px solod #000000'
          }}
        />
      );
    };
    const handleProfileClick = () => {
      navigate('/profile');
    };
    const logOut = () => {
      auth.signOut();
      navigate('/');
    };
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleClick}>
            <MenuIcon className={classes.menuIconWithBorder} />
          </IconButton>
        </div>
        <Drawer
          anchor="left"
          open={openMobileMenu}
          onClose={handleClose}
          classes={{
            paper: classes.drawer
          }}
          PaperProps={{
            style: {
              backgroundColor: '#F8F9FD'
            }
          }}
        >
          <div style={{ paddingTop: '6px', display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              className={`${classes.logo} ${classes.printLogo}`}
              onClick={() => navigate('/home')}
            >
              <img src="/images/PBLogo.png" alt="PrivateBlok-Logo" className="logo" />
              {/* <sup className={`${classes.betaLogo} ${classes.mobileBetaLogo}`}>
                {t('headder.logoVersion')}
              </sup> */}
            </IconButton>
            <IconButton className={classes.logo}>
              <CloseIcon sx={{ color: '#000000' }} onClick={handleClose} />
            </IconButton>
          </div>
          {authStatus === AuthStatus.SignedIn ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                paddingTop: '10px',
                paddingLeft: '10px',
                cursor: 'pointer',
                alignItems: 'center'
              }}
              onClick={handleProfileClick}
            >
              <Avatar
                sx={{
                  height: '40px',
                  width: '40px',
                  border: '1px solid #7DA3FD',
                  backgroundColor: '#E3EBFD',
                  color: '#0C0C0C',
                  fontSize: '15px'
                }}
                style={{ fontFamily: 'Satoshi', fontWeight: 'bold' }}
              >
                {nameChar}
              </Avatar>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0px 0px 4px 10px',
                  fontSize: '20px',
                  fontFamily: 'Satoshi'
                }}
              >
                {nameVal}
                {/* <br />
                {emailVal} */}
              </div>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
              <Button className={classes.buttonSignin}>
                <Link
                  to={'/signin'}
                  target={'_self'}
                  className="link-signin"
                  style={{
                    fontFamily: 'Satoshi',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    paddingLeft: '20px',
                    paddingRight: '20px'
                  }}
                >
                  {t('headder.signInButton')}
                </Link>
              </Button>
            </div>
          )}
          <ResponsiveProductMenu />
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <Button
              type="submit"
              onClick={() => {
                navigate('/home');
                dispatch(setCompanyMenuClickedStatus(true));
              }}
              className={classes.demoBtn}
              style={{ margin: '0px 16px' }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#FFFFFF',
                  textTransform: 'none'
                }}
              >
                {contactBtnText}
              </Typography>
            </Button>
          </div>
          <AuthIsSignedIn>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
              <Button onClick={logOut} className={classes.buttonSignin}>
                <Link
                  to={'/signin'}
                  target={'_self'}
                  className="link-signin"
                  style={{
                    fontFamily: 'Satoshi',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <LogoutIcon style={{ paddingRight: '10px' }} />
                  {t('headder.signOutButton')}
                </Link>
              </Button>
            </div>
          </AuthIsSignedIn>
        </Drawer>
        <Drawer
          anchor="left"
          open={searchOpen}
          onClose={handleSearchClose}
          classes={{
            paper: classes.drawer
          }}
          PaperProps={{
            style: {
              backgroundColor: '#F8F9FD'
            }
          }}
        >
          <div
            style={{
              paddingTop: '6px',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#F8F9FD'
            }}
          >
            <IconButton
              className={`${classes.logo} ${classes.printLogo}`}
              onClick={() => navigate('/home')}
            >
              <img src="/images/PBLogo.png" alt="PrivateBlok-Logo" className="logo" />
              {/* <sup className={`${classes.betaLogo} ${classes.mobileBetaLogo}`}>
                {t('headder.logoVersion')}
              </sup> */}
            </IconButton>
            <IconButton className={classes.logo}>
              <CloseIcon sx={{ color: '#000000' }} onClick={handleSearchClose} />
            </IconButton>
          </div>
          <Autocomplete
            // options={companyNameOptions}
            className={classes.root}
            getOptionLabel={(option) => option.field || ''}
            open={searchOpen}
            ListboxComponent={CustomListbox}
            PopperComponent={CustomPopper}
            renderInput={(params) => (
              <form noValidate>
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t('exploreAllCompanies.searchBarPlaceholder')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <SearchIcon className={classes.searchIcon} />
                        {params.InputProps.startAdornment}
                      </>
                    )
                  }}
                />
              </form>
            )}
            onChange={(event, value) => handleChange(event, value)}
          />
        </Drawer>
      </>
    );
  };

  const TabView = () => {
    const [openTabMenu, setOpenTabMenu] = useState(false);

    const handleClick = () => {
      setOpenTabMenu(true);
    };

    const handleClose = () => {
      setOpenTabMenu(false);
    };

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleClick}>
            <MenuIcon className={classes.menuIconWithBorder} />
          </IconButton>
        </div>
        <Drawer
          anchor="left"
          open={openTabMenu}
          onClose={handleClose}
          classes={{
            paper: classes.drawer
          }}
          PaperProps={{
            style: {
              backgroundColor: '#F8F9FD'
            }
          }}
        >
          <div style={{ paddingTop: '6px', display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              className={`${classes.logo} ${classes.printLogo}`}
              onClick={() => navigate('/home')}
            >
              <img src="/images/PBLogo.png" alt="PrivateBlok-Logo" className="logo" />
              {/* <sup className={`${classes.betaLogo} ${classes.mobileBetaLogo}`}>
                {t('headder.logoVersion')}
              </sup> */}
            </IconButton>
            <IconButton className={classes.logo}>
              <CloseIcon sx={{ color: '#000000' }} onClick={handleClose} />
            </IconButton>
          </div>
          <ResponsiveProductMenu />
        </Drawer>
      </>
    );
  };

  const [anchorElCompany, setAnchorElCompany] = useState(null);

  const handleClickCompany = (event) => {
    setAnchorElCompany(event.currentTarget);
  };

  const handleCloseCompany = () => {
    setAnchorElCompany(null);
  };

  const openCompany = Boolean(anchorElCompany);

  const handleClickByz = () => {
    navigate('/askbyz');
  };

  const checkRoute = (event, route) => {
    if (location.pathname.includes(route)) {
      event.preventDefault();
    }
  };
  const showWhiteLabel = () => {
    const sub_domain_url =
      window.location.protocol + '//' + whiteLabelDetails?.sub_domain + '.' + getMainDomain();
    if (whiteLabelDetails?.org_logo_url && sub_domain_url === window.location.origin) {
      return true;
    }
    return false;
  };
  return (
    !disableHeader && (
      <div className={classes.headerMainDiv}>
        <CheckRoute setCurrentActiveMenu={setCurrentActiveMenu} />
        <AppBar
          position="static"
          style={{
            background: '#FFFFFF',
            maxWidth: '1440px',
            margin: '0 auto',
            position: 'relative',
            zIndex: 1
          }}
          elevation={0}
        >
          <Toolbar disableGutters style={{ paddingRight: signinPadding }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '85px'
              }}
            >
              <div>
                <IconButton
                  className={`${classes.logo} ${classes.printLogo}`}
                  onClick={() => navigate('/home')}
                >
                  <Link to="/home" className={classes.logoLink}>
                    {showWhiteLabel() ? (
                      <WhiteLabelLogo whiteLabelDetails={whiteLabelDetails} />
                    ) : (
                      <img src="/images/PBLogo.png" alt="PrivateBlok-Logo" className="logo" />
                    )}
                  </Link>
                  {/* <sup className={classes.betaLogo}>{t('headder.logoVersion')}</sup> */}
                </IconButton>
              </div>
              {isMobile ? (
                <MobileView />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AuthIsSignedIn>
                    <Button
                      ref={buttonRefDashboard}
                      className={`${
                        storeShowTourGuide && storeCurrentStep === 1
                          ? classes.dashboardPopOverbutton +
                            ' ' +
                            classes.askBotPopOverbutton +
                            ' ' +
                            classes.askbotBefore
                          : ''
                      } ${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === t('headder.dashBoard') ? classes.gradientCls : ''
                      }`}
                    >
                      <Link
                        to={'/dashboard'}
                        target={'_self'}
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                        onClick={(e) => {
                          checkRoute(e, '/dashboard');
                        }}
                      >
                        {t('headder.dashBoard')}
                      </Link>
                    </Button>
                    <Button
                      className={`${
                        storeShowTourGuide && storeCurrentStep === 2
                          ? classes.askBotPopOverbutton + ' ' + classes.askbotBefore
                          : ''
                      } ${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === t('tab.AskBot') ? classes.gradientCls : ''
                      }`}
                      onClick={handleClickByz}
                      ref={buttonRef}
                    >
                      <Typography
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {t('tab.AskBot')}
                        </span>
                      </Typography>
                    </Button>
                    {storeShowTourGuide && currentStep && (
                      <StepPopover
                        value={anchorElStep}
                        currentStep={currentStep}
                        onClose={handleOncloseStep}
                        skip={handleSkip}
                      />
                    )}
                    <Button
                      ref={buttonRefTearSheet}
                      className={`${
                        storeShowTourGuide && storeCurrentStep === 3
                          ? classes.askBotPopOverbutton + ' ' + classes.tearSheetBefore
                          : ''
                      } ${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === t('tearSheet.customReportsHeadder')
                          ? classes.gradientCls
                          : ''
                      }`}
                    >
                      <Link
                        // to={'/report'}
                        to={isTearSheetEnabled ? '/report' : '/tearsheet'}
                        target={'_self'}
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                      >
                        {isTearSheetEnabled ? t('tearSheet.customReports') : t('tearSheet.heading')}
                      </Link>
                    </Button>
                    <Button
                      ref={buttonRefLiveTracker}
                      className={`${
                        storeShowTourGuide && storeCurrentStep === 4
                          ? classes.askBotPopOverbutton + ' ' + classes.tearSheetBefore
                          : ''
                      } ${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === t('tracker.heading') ? classes.gradientCls : ''
                      }`}
                    >
                      <Link
                        to={'/tracker'}
                        target={'_self'}
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                        onClick={(e) => {
                          checkRoute(e, '/tracker');
                        }}
                      >
                        {t('tracker.heading')}
                      </Link>
                    </Button>
                    <Button
                      className={`${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === t('headder.helpCenter') ? classes.gradientCls : ''
                      }`}
                    >
                      <Link
                        to={'/help-center'}
                        target={'_self'}
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                      >
                        {t('headder.helpCenter')}
                      </Link>
                    </Button>
                  </AuthIsSignedIn>
                  {showPricing === 'true' && (
                    <Button
                      className={`${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === t('headder.pricing') ? classes.gradientCls : ''
                      }`}
                    >
                      <Link
                        to={'/pricing'}
                        target={'_self'}
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                      >
                        {t('headder.pricing')}
                      </Link>
                    </Button>
                  )}
                  <AuthIsNotSignedIn>
                    <Button
                      className={`${classes.buttonLink} ${classes.showOnDesktop} ${
                        currentActiveMenu === 'Company' ? classes.gradientCls : ''
                      }`}
                      onClick={handleClickCompany}
                    >
                      <Typography
                        className={`${classes.anchorLink + ' link-button'}`}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '18px',
                          color: '#171717'
                        }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          Company <SeeMore style={{ padding: '0px 0px 0px 6px' }} />
                        </span>
                      </Typography>
                    </Button>
                    <Menu
                      open={openCompany}
                      anchorEl={anchorElCompany}
                      onClose={handleCloseCompany}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleCloseCompany();
                          navigate('/about');
                        }}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '16px',
                          color: '#171717'
                        }}
                      >
                        {t('headder.aboutUs')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseCompany();
                          window.open(
                            'https://www.linkedin.com/company/privateblok/jobs/',
                            '_blank'
                          );
                        }}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '16px',
                          color: '#171717'
                        }}
                      >
                        {t('header.careers')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseCompany();
                          navigate('/home');
                          dispatch(setCompanyMenuClickedStatus(true));
                        }}
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 500,
                          fontSize: '16px',
                          color: '#171717'
                        }}
                      >
                        {t('headder.contactUs')}
                      </MenuItem>
                    </Menu>
                  </AuthIsNotSignedIn>
                  <div>
                    <Button
                      type="submit"
                      onClick={() => {
                        navigate('/home');
                        dispatch(setCompanyMenuClickedStatus(true));
                      }}
                      className={classes.demoBtn}
                    >
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          color: '#FFFFFF',
                          textTransform: 'none'
                        }}
                      >
                        {contactBtnText}
                      </Typography>
                    </Button>
                  </div>
                  <AuthIsSignedIn>
                    <ProfilePopup
                      storeCurrentStep={storeCurrentStep}
                      storeShowTourGuide={storeShowTourGuide}
                      step4Props={step4Props}
                      setStep4Props={setStep4Props}
                      parentHandleClose={handleOncloseStep}
                      skip={handleSkip}
                    />
                  </AuthIsSignedIn>
                  <AuthIsNotSignedIn>
                    <Button className={classes.buttonSignin}>
                      <Link
                        to={'/signin'}
                        target={'_self'}
                        className="link-signin"
                        style={{
                          fontFamily: 'Satoshi',
                          fontWeight: 'normal',
                          fontSize: '16px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {t('headder.signInButton')}
                      </Link>
                    </Button>
                  </AuthIsNotSignedIn>
                  <div className="showOnTablet">
                    <TabView />
                  </div>
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    )
  );
};
export default Header;

Header.propTypes = {
  headerConfig: PropTypes.object.isRequired
};
