import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CompanyThemeSearch from './companyThemeSearch';
import ReportDropDown from './reportDropDown';
import { getApi } from '../../../../services/token';
import CustomButton from '../../../atoms/buttons/button';
import { hideShowMessage } from '../../../../helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  btnWidth: {
    width: '46.9%!important',
    height: '96%',
    borderRadius: '4px',
    transition: 'opacity 0.3s',
    margin: '0 16px',
    background:
      'var(--Gradients-Gradient4, linear-gradient(270deg, #CDF1F3 0%, #CDDBFF 100%))!important',
    '& p:first-child': {
      color: 'var(--Neutral-Colors-Color-800, var(--grey7, #171717))',
      fontFamily: 'Satoshi',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal'
    }
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
}));

const TearSheetSearchBar = ({ tearSheetSearchBarProps }) => {
  const {
    location,
    navigate,
    updateParamsInURL,
    searchParams,
    onSelectReportId,
    COMPANY_API_URL,
    resetSummary,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
    selectedFilter,
    getLimits
  } = tearSheetSearchBarProps;

  const { t } = useTranslation();

  const queryParamName = 'search';
  const report_success = searchParams.get('success');

  const query_search = searchParams.get(queryParamName);
  const entity_type = searchParams.get('entity_type') || 'company';
  const query_report_id = searchParams.get('report_id');
  const query_definition_type = searchParams.get('definition_type');

  const [selectedItem, setSelectedItem] = useState(null);

  const [activeEntityType, setActiveEntityType] = useState('company');

  const onReportSucess = async () => {
    await getLimits();
    if (report_success) {
      if (
        report_success === 'create' ||
        report_success === 'edit' ||
        report_success === 'template-library'
      ) {
        hideShowMessage(t('tearSheet.InProg'), setSuccessMessage);
      } else if (report_success === 'report-deleted') {
        hideShowMessage(t('tearSheet.reportDeleted'), setSuccessMessage);
      }
      updateParamsInURL({
        navigate,
        location,
        removeParams: ['success']
      });
    }
  };

  useEffect(() => {
    onReportSucess();
  }, [report_success]);

  useEffect(() => {
    if (selectedFilter) setActiveEntityType(selectedFilter);
  }, [selectedFilter]);

  const [reportData, setReportData] = useState([]);
  const [reportId, setReportId] = useState(query_report_id || '');

  const handleReportChange = (e) => {
    const report_id = e.target.value;
    setReportId(report_id);

    if (report_id) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { report_id: report_id }
      });
    }
  };

  useEffect(() => {
    if (selectedItem && reportId) {
      onSelectReportId(selectedItem, reportId);
    } else {
      resetSummary();
    }
  }, [selectedItem, reportId]);

  const onTemplateLib = () => {
    if (selectedItem?.entity_id)
      updateParamsInURL({
        navigate,
        location,
        addParams: { entity_type, entity_id: selectedItem?.entity_id, action: 'template-library' }
      });
  };

  const getReports = async (selectedCompany) => {
    if (selectedCompany?.entity_id) {
      try {
        setLoading(true);
        const reportData = await getApi(
          `${COMPANY_API_URL}/v2/report?entity_type=${entity_type}&entity_id=${selectedCompany.entity_id}`
        );
        setReportData(reportData.data.data || []);
      } catch (error) {
        hideShowMessage(
          'Error occurred while retrieving options for the report. Please try again.',
          setErrorMessage
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!query_report_id) setReportId('');

    if (!(selectedItem && query_search)) {
      setReportData([]);
    }
  }, [query_search, selectedItem, query_report_id]);

  const classes = useStyles();
  console.log(classes);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <CompanyThemeSearch
          {...tearSheetSearchBarProps}
          getReports={getReports}
          COMPANY_API_URL={COMPANY_API_URL}
          queryParamName={queryParamName}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          activeEntityType={activeEntityType}
          query_definition_type={query_definition_type}
        />
      </Grid>
      <Grid item xs={3}>
        <ReportDropDown
          {...tearSheetSearchBarProps}
          reportData={reportData}
          setReportId={setReportId}
          reportId={reportId}
          handleReportChange={handleReportChange}
          selectedItem={selectedItem}
          getReports={getReports}
          query_report_id={query_report_id}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomButton
          btnText={`Template Library`}
          handleClick={selectedItem?.entity_id ? onTemplateLib : undefined}
          styleClass={classes.btnWidth + ` ${!selectedItem?.entity_id ? classes.disabled : ''}`}
        />
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
};

export default TearSheetSearchBar;
