import { getApi } from '../../../../services/token';
import { useState } from 'react';
import Autocomplete from '../../../atoms/searchableAutocomplete';
import { entitesFilterToDisplayMapTheme } from '../../../../constants/tearSheet/tearSheet';

const CompanyThemeSearch = ({
  // selectedFilter = '',
  getReports,
  updateParamsInURL,
  navigate,
  location,
  COMPANY_API_URL,
  queryParamName,
  selectedItem,
  setSelectedItem,
  // setLoading,
  // setErrorMessage,
  activeEntityType,
  query_definition_type
}) => {
  const isPresistSearchValue = true;
  const page_size = 15;

  const [selectedTab, setSelectedTab] = useState(query_definition_type || 'pre_defined');

  const getSimilarEntities = async (search_text, activeEntityType) => {
    if (activeEntityType === 'theme' && search_text) {
      const queryParams = new URLSearchParams({
        theme_phrase: search_text
      });
      const { data } = await getApi(
        `${COMPANY_API_URL}/v1/fts/similarity-theme-search?${queryParams.toString()}`
      );
      return data?.data || [];
    }
    return [];
  };

  const getAutocompleteOptions = async (parameters) => {
    const [input, page, activeEntityType, selectedTab] = parameters;
    const queryParams = new URLSearchParams({
      entity_types: activeEntityType === 'theme' ? 'theme,sub_theme' : activeEntityType || '',
      page_size: page_size || 15,
      page_number: page || 1,
      search_text: input || '',
      definition_type: activeEntityType === 'theme' ? selectedTab || 'pre_defined' : ''
    });

    try {
      // setLoading(true);
      const { data } = await getApi(
        `${COMPANY_API_URL}/v1/entity/search?${queryParams.toString()}`
      );
      const chipsData = await getSimilarEntities(input, activeEntityType);
      const newOptions = data?.data?.items || [];
      const total_records = data?.data?.metadata?.total_records;
      // setLoading(false);
      return { newOptions, total_records, chipsData };
    } catch (error) {
      console.error(error);
      // setLoading(false);
      // setErrorMessage(
      //   `Error occurred while retrieving options for the ${selectedFilter}. Please try again.`
      // );
    }
  };

  const onAutoCompleteChange = async (selectedOption) => {
    setSelectedItem(selectedOption);
    if (
      selectedOption?.entity_id &&
      (!selectedItem || selectedItem.entity_id != selectedOption.entity_id)
    ) {
      await getReports(selectedOption);
    } else if (selectedOption?.entity_name) {
      updateParamsInURL({
        navigate,
        location,
        addParams: { [queryParamName]: selectedOption.entity_name },
        removeParams: ['report_id']
      });
    }
  };

  const onSelectedTabOption = (value) => {
    if (value && (value === 'pre_defined' || value === 'user_defined')) {
      setSelectedTab(value);
      updateParamsInURL({
        navigate,
        location,
        addParams: { definition_type: value }
      });
    }
  };

  const handleOnBlur = () => {
    if (!selectedItem) {
      updateParamsInURL({
        navigate,
        location,
        removeParams: ['action', 'entity_id', queryParamName, 'report_id']
      });
      // setSelectedTab('');
    }
  };

  let themeOptions = {};

  if (activeEntityType === 'theme') {
    themeOptions = {
      tabOptions: entitesFilterToDisplayMapTheme,
      selectedTab,
      onSelectedTabOption
    };
  }

  return (
    <>
      <Autocomplete
        searchableProps={{
          getAutocompleteOptions,
          isPresistSearchValue,
          onAutoCompleteChange,
          queryParamName,
          APIDependency: [activeEntityType, selectedTab],
          ...themeOptions,
          handleOnBlur,
          activeEntityType,
          selectedTab,
          showChips: activeEntityType === 'theme'
        }}
      />
    </>
  );
};

export default CompanyThemeSearch;
